import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, values } from 'lodash';

import { filterPrescriptionsNeedsReview } from 'store/thunks/workQueueThunks';

import { SortKey } from 'enums/common.enum';
import { prescriptionReviewStatus } from 'common/constants/prescriptionStatus';

const PrescriptionReviewFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.prescriptionReview?.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterPrescriptionsNeedsReview({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(filterPrescriptionsNeedsReview({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter?.drugName && (
        <Chip
          label={`Drug Name = ${filter.drugName}`}
          variant='contained'
          onDelete={removeFilter(SortKey.DRUG_NAME)}
        />
      )}

      {filter?.requestedDate && (
        <Chip
          label={`Requested Date = ${format(new Date(filter.requestedDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(SortKey.REQUESTED_DATE)}
        />
      )}

      {filter?.reviewStatus && (
        <Chip
          label={`Status =  ${prescriptionReviewStatus[filter.reviewStatus]}`}
          variant='contained'
          onDelete={removeFilter(SortKey.REVIEW_STATUS)}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default PrescriptionReviewFilterStatusBar;
