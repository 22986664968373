import React from 'react';
import { format } from 'date-fns';
import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, compact, find, isBoolean, isEmpty, map, omit, values } from 'lodash';

import { updatePrescriptionOrdersFilter } from 'store/thunks/orderStatusThunks';
import { ExternalStatusShortCode, OrderStatusFieldKeys, OrderType } from 'enums/requestStatus.enum';

const EScriptOrdersFilterStatusBar = () => {
  const dispatch = useDispatch();
  const partners = useSelector(
    ({ auth, partner: partnerStore }) => partnerStore[auth.currentTenantId]
  );
  const filter = useSelector(({ orderStatus: { eScriptOrders } }) => ({
    ...omit(eScriptOrders.filter, 'orderType'),
  }));

  const removeFilter = (filterName) => () => {
    dispatch(
      updatePrescriptionOrdersFilter({
        filter: {
          ...filter,
          [filterName]: undefined,
          orderType: OrderType.E_SCRIPT,
        },
      })
    );
  };

  const getDateRange = (dateRange) => {
    const dates = map(dateRange, (date) => format(new Date(date), 'MM/dd/yyyy'));

    return dates.length === 1 ? `${dates[0]}` : `${dates[0]} - ${dates[1]}`;
  };

  const removeAllFilter = () =>
    dispatch(updatePrescriptionOrdersFilter({ filter: { orderType: OrderType.E_SCRIPT } }));

  return (
    <Stack direction='row' spacing={1}>
      {filter?.referenceId && (
        <Chip
          label={`Reference ID = ${filter.referenceId}`}
          variant='contained'
          onDelete={removeFilter(OrderStatusFieldKeys.REFERENCE_ID)}
        />
      )}

      {filter?.externalStatus && (
        <Chip
          label={`Order External Status = ${ExternalStatusShortCode[filter.externalStatus]}`}
          variant='contained'
          onDelete={removeFilter(OrderStatusFieldKeys.EXTERNAL_STATUS)}
        />
      )}

      {filter?.patientName && (
        <Chip
          label={`Patient Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter(OrderStatusFieldKeys.PATIENT_NAME)}
        />
      )}

      {filter?.partnerId && (
        <Chip
          label={`Partner = ${
            find(partners, (partner) => partner?.partnerUuid === filter?.partnerId)?.partnerName
          }`}
          variant='contained'
          onDelete={removeFilter(OrderStatusFieldKeys.PARTNER)}
        />
      )}

      {filter?.externalPatientId && (
        <Chip
          label={`External Patient ID = ${filter.externalPatientId}`}
          variant='contained'
          onDelete={removeFilter(OrderStatusFieldKeys.EXTERNAL_PATIENT_ID)}
        />
      )}

      {filter?.pomOrderId && (
        <Chip
          label={`Order ID = ${filter.pomOrderId}`}
          variant='contained'
          onDelete={removeFilter(OrderStatusFieldKeys.ORDER_ID)}
        />
      )}

      {filter?.createdAt && (
        <Chip
          label={`Created At = ${getDateRange(filter.createdAt)}`}
          variant='contained'
          onDelete={removeFilter(OrderStatusFieldKeys.CREATED_AT)}
        />
      )}

      {filter?.orderStatus && (
        <Chip
          label={`Order Status = ${capitalize(filter?.orderStatus)}`}
          variant='contained'
          onDelete={removeFilter(OrderStatusFieldKeys.STATUS)}
        />
      )}

      {!isEmpty(compact(values(filter))) && !isBoolean(compact(values(filter))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default EScriptOrdersFilterStatusBar;
