/* eslint-disable import/prefer-default-export */
import { debounce } from 'lodash';

import { ordersListActions } from 'store/slices/ordersListSlice';
import { getOrdersApi } from 'api/order';

import { handleError } from './errorHandlerThunks';

export const getOrdersByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { sortBy, filter } = getState().ordersList;

    dispatch(ordersListActions.updateCurrentPage({ page }));

    return getOrdersApi({ page, sortBy, filter })
      .then(({ count, orders }) => {
        dispatch(ordersListActions.loadOrdersToPage({ page, orders, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedGetOrders = debounce(({ dispatch }) => {
  return dispatch(getOrdersByPage({ page: 1 }));
}, 1000);

export const sortOrdersList =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(ordersListActions.updateSortBy({ sortBy }));

    debouncedGetOrders({ dispatch });
  };

export const filterOrdersList =
  ({ filter }) =>
  (dispatch) => {
    dispatch(ordersListActions.updateFilter({ filter }));

    debouncedGetOrders({ dispatch });
  };
