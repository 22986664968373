import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, values } from 'lodash';

import { filterOrdersList } from 'store/thunks/ordersListThunk';
import orderStatus from 'common/constants/orderStatus';

const OrderFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ ordersList }) => ordersList.filter);

  const removeFilter = (filterName) => () => {
    dispatch(filterOrdersList({ filter: { ...filter, [filterName]: undefined } }));
  };

  const removeAllFilter = () => dispatch(filterOrdersList({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.orderId && (
        <Chip
          label={`Order Id = ${filter.orderId}`}
          variant='contained'
          onDelete={removeFilter('orderId')}
        />
      )}

      {filter.status && (
        <Chip
          label={`Status = ${orderStatus[filter.status]}`}
          variant='contained'
          onDelete={removeFilter('status')}
        />
      )}

      {filter.referenceId && (
        <Chip
          label={`Reference Id = ${filter.referenceId}`}
          variant='contained'
          onDelete={removeFilter('referenceId')}
        />
      )}

      {filter.createdAt && (
        <Chip
          label={`Created At = ${format(new Date(filter.createdAt), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('createdAt')}
        />
      )}

      {filter.rxNumber && (
        <Chip
          label={`Rx Number = ${filter.rxNumber}`}
          variant='contained'
          onDelete={removeFilter('rxNumber')}
        />
      )}

      {filter.updatedAt && (
        <Chip
          label={`Updated At = ${filter.updatedAt}`}
          variant='contained'
          onDelete={removeFilter('updatedAt')}
        />
      )}

      {filter.flatSearch && (
        <Chip
          label={`Search = ${filter.flatSearch}`}
          variant='contained'
          onDelete={removeFilter('flatSearch')}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default OrderFilterStatusBar;
