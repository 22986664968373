import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, omit, values } from 'lodash';

import orderEnum from 'enums/order.enum';

import { filterOrders } from 'store/thunks/workQueueThunks';

const defaultOrderStatus = [orderEnum.ORDER_VERIFICATION, orderEnum.ORDER_RE_VERIFICATION];

const OrderVerificationStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue: { orderVerification } }) => orderVerification.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterOrders({
        filter: {
          ...filter,
          [filterName]: undefined,
          ...(filterName === 'orderStatus' && {
            orderStatus: defaultOrderStatus,
          }),
        },
      })
    );
  };

  const removeAllFilter = () =>
    dispatch(filterOrders({ filter: { orderStatus: defaultOrderStatus } }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.orderId && (
        <Chip
          label={`Order Id = ${filter.orderId}`}
          variant='contained'
          onDelete={removeFilter('orderId')}
        />
      )}

      {filter.needsByDate && (
        <Chip
          label={`Needs By Date = ${format(new Date(filter.needsByDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('needsByDate')}
        />
      )}

      {filter.patientName && (
        <Chip
          label={`Patient Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter?.orderStatus?.length < 2 && (
        <Chip
          label={`Status= ${filter.orderStatus}`}
          variant='contained'
          onDelete={removeFilter('orderStatus')}
        />
      )}

      {!isEmpty(compact(values(omit(filter, ['orderStatus'])))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default OrderVerificationStatusBar;
