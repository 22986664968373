import { createSlice } from '@reduxjs/toolkit';
import { first, flatten, map } from 'lodash';

const initialState = {
  username: '',
  email: '',
  userId: null,
  firstName: '',
  lastName: '',
  currentTenantId: null,
  currentTenantRoles: [],
  fulfillingPharmacyIds: [],
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState(state) {
      Object.assign(state, initialState);
    },
    loadUserData(state, action) {
      const { userData } = action.payload;
      state.username = userData.email;
      Object.assign(state, userData);

      if (!state.currentTenantId) {
        const firstTenantId = first(userData.tenants)?.id;
        const tenantDetails = userData.userRoles?.[firstTenantId] || {};

        const fulfillingPharmacyIds = flatten(
          map(tenantDetails.allowedFulfillingPharmacies, (item) => Object.keys(item))
        );

        state.currentTenantId = firstTenantId || null;
        state.currentTenantRoles = tenantDetails.roles || [];
        state.fulfillingPharmacyIds = fulfillingPharmacyIds;
      }
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
