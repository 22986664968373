import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { map, startCase } from 'lodash';
import { Grid, Typography } from '@mui/material';
import useRoles from 'common/hooks/useRoles';

import AdminCenterOptionTileView from './AdminCenterOptionTileView';

const AdminCenter = () => {
  const hasAccess = useRoles();
  const { pathname } = useLocation();

  const getCurrentPath = (path) => {
    const parts = path.split('/').filter(Boolean);
    const formattedParts = map(parts, (part) => startCase(part));
    return formattedParts?.join(' / ');
  };

  useEffect(() => {
    document.title = 'Admin Center - SmartOS';
  }, []);

  return (
    <>
      <Grid container sx={{ px: 3 }} direction='column'>
        <Grid item container justifyContent='space-between' sx={{ mb: 1 }}>
          <Typography component='h1' variant='h5'>
            {getCurrentPath(pathname)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ mx: 1 }}>
        {pathname === '/adminCenter' ? (
          <>
            <Grid container sx={{ mx: 2 }} gap={4}>
              {hasAccess.viewDrug && (
                <Grid item sx={{ mb: 2 }}>
                  <Typography variant='h6' sx={{ fontWeight: 600, my: 2 }}>
                    Drug
                  </Typography>
                  <AdminCenterOptionTileView
                    pathName='/adminCenter/drugManagement'
                    title='Drug Management'
                    description='Add and manage drug profiles'
                  />
                </Grid>
              )}

              {hasAccess.viewSigManagement && (
                <Grid item>
                  <Typography variant='h6' sx={{ fontWeight: 600, my: 2 }}>
                    Sig
                  </Typography>
                  <AdminCenterOptionTileView
                    pathName='/adminCenter/sigManagement'
                    title='Sig Management'
                    description='Add and edit existing drug sigs'
                  />
                </Grid>
              )}
            </Grid>

            <Grid container sx={{ m: 2 }} gap={4}>
              {hasAccess.viewSystem && (
                <Grid item>
                  <Typography variant='h6' sx={{ fontWeight: 600, mb: 2 }}>
                    Systems
                  </Typography>
                  <AdminCenterOptionTileView
                    pathName='/adminCenter/requestStatus'
                    title='Request Status'
                    description='View and manage requests from API'
                  />
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Outlet />
        )}
      </Grid>
    </>
  );
};

export default AdminCenter;
